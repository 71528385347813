export const FEATURE_TOGGLE_DEFAULT_VALUE = "off"

export const FEATURE_TOGGLE_PROVIDER_SPLIT = "split"
export const FEATURE_TOGGLE_PROVIDER_OPTIMIZELY = "optimizely"

export const FeatureToggleProviderNames = {
	[FEATURE_TOGGLE_PROVIDER_SPLIT]: "Split.io",
	[FEATURE_TOGGLE_PROVIDER_OPTIMIZELY]: "Optimizely",
}

export const FeatureToggleNames = {
	APP_INGRESS: "APP_INGRESS",
	CASHOUT: "CASHOUT",
	DISBURSEMENT_METHODS_AVAILABLE: "DISBURSEMENT_METHODS_AVAILABLE",
	PAYMENT_METHODS_AVAILABLE: "PAYMENT_METHODS_AVAILABLE",
	QUIQCHAT: "QUIQCHAT",
	REQUEST_SERVICE_ENABLED: "REQUEST_SERVICE_ENABLED",
	REQUEST_SERVICE_FALLOUT_REPORT: "REQUEST_SERVICE_FALLOUT_REPORT",
	REQUEST_SERVICE_GUEST_ENABLED: "REQUEST_SERVICE_GUEST_ENABLED",
	REQUEST_SERVICE_MULTI_HVAC_TUNEUP: "REQUEST_SERVICE_MULTI_HVAC_TUNEUP",
	REQUEST_SERVICE_MULTI_SAME_ITEM: "REQUEST_SERVICE_MULTI_SAME_ITEM",
	REQUEST_SERVICE_POPUP_RECALL: "REQUEST_SERVICE_POPUP_RECALL",
	REQUEST_SERVICE_POPUP_SYMPTOM_VIDEO: "REQUEST_SERVICE_POPUP_SYMPTOM_VIDEO",
	REQUEST_SERVICE_STREEM_ENABLED: "REQUEST_SERVICE_STREEM_ENABLED",
	REQUEST_SERVICE_HIGH_RISE_POPUP: "REQUEST_SERVICE_HIGH_RISE_POPUP",
	REQUEST_SERVICE_WATER_HEATER_LEGACY_STYLE: "REQUEST_SERVICE_WATER_HEATER_LEGACY_STYLE",
	PAYMENT_HISTORY_NEW_UPDATES: "PAYMENT_HISTORY_NEW_UPDATES",
	UPCOMING_COVERAGE_CHANGE_ALERT: "UPCOMING_COVERAGE_CHANGE_ALERT",
}

export const FeatureToggleNamesByProvider = {
	[FEATURE_TOGGLE_PROVIDER_SPLIT]: {
		[FeatureToggleNames.PAYMENT_METHODS_AVAILABLE]: "MA_payment_methods_available",
		[FeatureToggleNames.QUIQCHAT]: "MA_quiqchat",
		[FeatureToggleNames.REQUEST_SERVICE_ENABLED]: "MA_request_service_enabled",
		[FeatureToggleNames.REQUEST_SERVICE_FALLOUT_REPORT]: "MA_request_service_fallout_report",
		[FeatureToggleNames.REQUEST_SERVICE_GUEST_ENABLED]: "MA_request_service_guest",
		[FeatureToggleNames.REQUEST_SERVICE_MULTI_HVAC_TUNEUP]: "MA_request_service_multi_hvac_tune_up",
		[FeatureToggleNames.REQUEST_SERVICE_MULTI_SAME_ITEM]: "MA_request_service_multi_same_item",
		[FeatureToggleNames.REQUEST_SERVICE_POPUP_RECALL]: "MA_request_service_popup_recall",
		[FeatureToggleNames.REQUEST_SERVICE_POPUP_SYMPTOM_VIDEO]: "MA_request_service_popup_symptom_video",
		[FeatureToggleNames.REQUEST_SERVICE_STREEM_ENABLED]: "MA_request_service_streem",
	},
	[FEATURE_TOGGLE_PROVIDER_OPTIMIZELY]: {
		[FeatureToggleNames.APP_INGRESS]: "app_ingress",
		[FeatureToggleNames.CASHOUT]: "cashout",
		[FeatureToggleNames.DISBURSEMENT_METHODS_AVAILABLE]: "disbursement_methods_available",
		[FeatureToggleNames.PAYMENT_METHODS_AVAILABLE]: "payment_methods_available",
		[FeatureToggleNames.QUIQCHAT]: "quiqchat",
		[FeatureToggleNames.REQUEST_SERVICE_ENABLED]: "request_service_enabled",
		[FeatureToggleNames.REQUEST_SERVICE_FALLOUT_REPORT]: "request_service_fallout_report",
		[FeatureToggleNames.REQUEST_SERVICE_GUEST_ENABLED]: "request_service_guest",
		[FeatureToggleNames.REQUEST_SERVICE_MULTI_HVAC_TUNEUP]: "request_service_multi_hvac_tune_up",
		[FeatureToggleNames.REQUEST_SERVICE_MULTI_SAME_ITEM]: "request_service_multi_same_item",
		[FeatureToggleNames.REQUEST_SERVICE_POPUP_RECALL]: "request_service_popup_recall",
		[FeatureToggleNames.REQUEST_SERVICE_POPUP_SYMPTOM_VIDEO]: "request_service_popup_symptom_video",
		[FeatureToggleNames.REQUEST_SERVICE_STREEM_ENABLED]: "request_service_streem",
		[FeatureToggleNames.REQUEST_SERVICE_HIGH_RISE_POPUP]: "request_service_high_rise_popup",
		[FeatureToggleNames.REQUEST_SERVICE_WATER_HEATER_LEGACY_STYLE]: "request_service_water_heater_legacy_style",
		[FeatureToggleNames.PAYMENT_HISTORY_NEW_UPDATES]: "payment_history_new_updates",
		[FeatureToggleNames.UPCOMING_COVERAGE_CHANGE_ALERT]: "upcoming_coverage_change_alert",
	},
}
