import React from "react"
import { connect } from "react-redux"
import { AppActions } from "../store/app/actions"

class PageOverlay extends React.Component {
	handleOverlayClick(e) {
		e.preventDefault()

		this.props.hidePageOverlay()
	}

	render() {
		return (
			<div
				id="page-overlay"
				onClick={(e) => this.handleOverlayClick(e)}
				style={{
					display: this.props.showPageOverlay ? "block" : "none",
				}}
			></div>
		)
	}
}

const mapStateToProps = (state) => ({
	showPageOverlay: state.app.showPageOverlay,
})

const mapDispatchToProps = (dispatch) => ({
	hidePageOverlay: () => dispatch(AppActions.hidePageOverlay()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageOverlay)
