import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import PaymentService from "../../services/payment"
import { makeCancelable } from "../../utils"
import contractNamespace from "../contract/namespace"
import { addPaymentInfoEnd, addPaymentInfoStart } from "../contract/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const setPastDuePayment = actionsBuilder.createAction("setPastDuePayment", (state, { index, pastDuePayment }) => {
	const pastDuePayments = state[namespace].paymentInfo.pastDueInvoices.slice()

	pastDuePayments[index] = Object.assign({}, pastDuePayments[index], pastDuePayment)

	state[namespace].paymentInfo.pastDueInvoices = pastDuePayments
})

export const resetPayAmount = actionsBuilder.createAction("resetPayAmount", (state) => {
	if (state[namespace].paymentInfo?.pastDueInvoices) {
		const pastDueInvoices = state[namespace].paymentInfo.pastDueInvoices.slice()

		pastDueInvoices.forEach((pastDueInvoice) => {
			pastDueInvoice.payAmount = pastDueInvoice.amount
		})

		state[namespace].paymentInfo.pastDueInvoices = pastDueInvoices
	}
})

export const fetchPaymentInfoStart = actionsBuilder.createAction("fetchPaymentInfoStart", (state, loadingPromise) => {
	state[namespace].paymentInfo = null
	state[namespace].paymentInfoLoading = true
	state[namespace].paymentInfoLoadingPromise = loadingPromise

	state[namespace].paymentInfoError = false
})

export const fetchPaymentInfoEnd = actionsBuilder.createAction("fetchPaymentInfoEnd", (state, paymentInfo) => {
	if (paymentInfo) {
		state[namespace].paymentInfo = paymentInfo

		if (paymentInfo.pastDueInvoices) {
			paymentInfo.pastDueInvoices.forEach((pastDueInvoice) => {
				pastDueInvoice.payAmount = pastDueInvoice.amount
			})
		}
	}

	state[namespace].paymentInfoError = !paymentInfo

	state[namespace].paymentInfoLoading = false
	state[namespace].paymentInfoLoadingPromise = null
})

export const resetPastDuePaymentsErrorState = actionsBuilder.createAction("resetPastDuePaymentsErrorState", (state) => {
	if (state[namespace].paymentInfo?.pastDueInvoices) {
		const pastDuePayments = state[namespace].paymentInfo.pastDueInvoices.slice()

		pastDuePayments.forEach((pastDue) => {
			pastDue.error = false
		})

		state[namespace].paymentInfo.pastDueInvoices = pastDuePayments
	}
})

export const PaymentActions = {
	fetchPaymentInfo: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { paymentInfoLoadingPromise } = state[namespace]

		if (paymentInfoLoadingPromise) {
			paymentInfoLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(PaymentService.fetchPaymentInfo(current.contractID))
		dispatch(fetchPaymentInfoStart(cancelablePromise))
		dispatch(addPaymentInfoStart({ loadingPromise: cancelablePromise, contractID: current.contractID }))

		try {
			const paymentInfo = await cancelablePromise.promise

			dispatch(fetchPaymentInfoEnd(paymentInfo))
			dispatch(addPaymentInfoEnd({ paymentInfo, contractID: current.contractID }))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchPaymentInfoEnd())
				dispatch(addPaymentInfoEnd({ contractID: current.contractID }))
			}
		}
	},
	setPaymentInfoFromCurrentContract: () => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (!current) {
			return
		}

		dispatch(fetchPaymentInfoEnd(current.paymentInfo))
	},
	setPastDuePayment: (index, pastDuePayment) => (dispatch) => {
		dispatch(setPastDuePayment({ index, pastDuePayment }))
	},
	resetPayAmount: () => (dispatch) => {
		dispatch(resetPayAmount())
	},
	resetPastDuePaymentsErrorState: () => (dispatch) => {
		dispatch(resetPastDuePaymentsErrorState())
	},
}

export const actions = actionsBuilder.exportActions()
