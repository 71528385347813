export default {
	paymentInfoLoading: false,
	paymentInfoLoadingPromise: null,
	paymentInfoError: false,
	paymentInfo: null,

	totalPayNowAmount() {
		return this.paymentInfo?.pastDueInvoices
			.map((pastDueInvoice) => pastDueInvoice.payAmount)
			.reduce((prev, amount) => prev + parseFloat(amount || "0"), 0)
			.toFixed(2)
	},

	totalCreditsAmount() {
		const parsedAmount = parseFloat(`${this.paymentInfo?.credits?.totalAmount?.units}.${this.paymentInfo?.credits?.totalAmount?.nanos}`)

		if (!isNaN(parsedAmount) && parsedAmount >= 0.01) {
			return parsedAmount
		} else {
			return 0
		}
	},

	hasNextAutoPayment() {
		return (
			this.paymentInfo &&
			this.paymentInfo.automaticPayment &&
			this.paymentInfo.automaticPayment.paymentDate !== "" &&
			this.paymentInfo.automaticPayment.amount > 0
		)
	},

	hasSavedPaymentMethod() {
		return this.paymentInfo && this.paymentInfo.methods && this.paymentInfo.methods.length > 0
	},
}
