import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import ExtendedContext from "./components/ExtendedContext"
import Loading from "./pages/Loading"
import ErrorPage from "./pages/Error"
import ConfigService from "./services/config"
import ZestyService from "./services/zesty"
import { ZestyActions } from "./store/zesty/actions"
import { getSiteName, setPageTitle, applyTranslations, setTheme, isSiteAHS } from "./utils"
import {
	App as BlueprintApp,
	AppContext as BlueprintAppContext,
	DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES,
} from "@ftdr/blueprint-components-react"
import PageRouter from "./router"
import { DefaultPropsContextProvider } from "@ftdr/blueprint-components-react"

const App = ({ loadRequiredZestyContent }) => {
	const [isLoading, setLoading] = useState(true)
	const [hasError, setError] = useState(false)
	const [appSettings, setAppSettings] = useState({
		notificationsWidth: "50%",
		notificationsMinWidth: 320,
		notificationsMaxWidth: 600,
	})

	const onMount = async () => {
		try {
			setPageTitle("MyAccount")
			setTheme()

			// TODO: changge translations to use new environment in Zesty (non-prod and prod) at ConfigService.config.zesty.env
			const textTemplatesByLanguage = await ZestyService.getTranslations(ConfigService.config.env)
			const language = "en_US" // TODO: Load from cookie or something to remember user language whenever we support changing user language

			setAppSettings({
				...appSettings,
				language,
				textTemplatesByLanguage,
			})

			await loadRequiredZestyContent(ConfigService.config.zesty.env)
		} catch (err) {
			console.error(err)

			setError(true)
		}

		setLoading(false)
	}

	useEffect(() => {
		onMount()
	}, [])

	if (isLoading) {
		return <Loading />
	}
	if (hasError) {
		return <ErrorPage />
	}

	// Add "localizedContent" function to the app context
	const extendedContext = {
		localizedContent: (content) => applyTranslations(content, appSettings.textTemplatesByLanguage, appSettings.language),
	}

	return (
		<BlueprintApp appSettings={appSettings}>
			<ExtendedContext value={extendedContext} Context={BlueprintAppContext}>
				<DefaultPropsContextProvider value={isSiteAHS() ? DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES : null}>
					<PageRouter />
				</DefaultPropsContextProvider>
			</ExtendedContext>
		</BlueprintApp>
	)
}

const mapDispatchToProps = (dispatch) => ({
	loadRequiredZestyContent: (environment) => {
		return Promise.all([
			dispatch(ZestyActions.getHeader(getSiteName())),
			dispatch(ZestyActions.getFooter(getSiteName())),
			dispatch(ZestyActions.getUserAlerts(getSiteName(), environment)),
		])
	},
})

export default connect(null, mapDispatchToProps)(App)
