import React, { useContext, useEffect, useState } from "react"
import { isDesktop } from "./utils"
import ContractService from "./services/contract"

export const withDesktopViewHook = (Component) => {
	return function WrappedComponent(props) {
		const isDesktop = useIsDesktop()
		return <Component {...props} isDesktop={isDesktop} />
	}
}

// TODO - Look into replacement for isMobile, isDesktop,useDesktop with BDS Screen size context provider
export const useIsDesktop = () => {
	const [value, setValue] = useState(isDesktop())

	useEffect(() => {
		const resizeHandler = () => {
			setValue(isDesktop())
		}

		window.addEventListener("resize", resizeHandler)

		return () => {
			window.removeEventListener("resize", resizeHandler)
		}
	}, [])

	return value
}

export const usePilotGroup = (pilotGroupType, contractID, sessionUUID) => {
	const [isInPilotGroup, setInPilotGroup] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (!pilotGroupType) {
			setLoading(false)
			setInPilotGroup(false)
			return
		}

		setLoading(true)

		if (!contractID) {
			return
		}

		let cancelled = false

		ContractService.checkPilotGroup(contractID, pilotGroupType, sessionUUID)
			.then((res) => {
				if (!cancelled) {
					setInPilotGroup(res)
					setLoading(false)
				}
			})
			.catch((err) => {
				if (!cancelled) {
					setInPilotGroup(false)
					setLoading(false)
				}
			})

		return () => (cancelled = true)
	}, [pilotGroupType, contractID, sessionUUID])

	return { loading, isInPilotGroup }
}
