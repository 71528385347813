import React, { useContext, useEffect, useState } from "react"
import { FeatureTogglesContext } from "./Context"
import { SplitContext, SplitFactory } from "@splitsoftware/splitio-react"
import * as optimizelyReactSDK from "@optimizely/react-sdk"
import ConfigService from "../../services/config"
import {
	FEATURE_TOGGLE_DEFAULT_VALUE,
	FEATURE_TOGGLE_PROVIDER_OPTIMIZELY,
	FEATURE_TOGGLE_PROVIDER_SPLIT,
	FeatureToggleNamesByProvider,
} from "./constants"
import Loading from "../../pages/Loading"

const FeatureTogglesProvider = ({ children }) => {
	const provider = ConfigService.config.featureTogglesProvider
	const config = ConfigService.config[provider]

	switch (provider) {
		case FEATURE_TOGGLE_PROVIDER_SPLIT: {
			return SplitFeatureTogglesProvider({ config, children })
		}
		case FEATURE_TOGGLE_PROVIDER_OPTIMIZELY: {
			return OptimizelyFeatureTogglesProvider({ config, children })
		}
	}

	return <>{children}</>
}

const SplitFeatureTogglesProvider = ({ config, children }) => {
	return (
		<SplitFactory config={{ core: config }}>
			<InnerSplitFeatureTogglesProvider>{children}</InnerSplitFeatureTogglesProvider>
		</SplitFactory>
	)
}

const InnerSplitFeatureTogglesProvider = ({ children }) => {
	const splitContext = useContext(SplitContext)

	const getFeatureToggle = (key, featureToggleName, attributes) => {
		if (!splitContext?.isReady) {
			return FEATURE_TOGGLE_DEFAULT_VALUE
		}

		// Map some renamed attributes back to their legacy attribute
		if (attributes.payment_method_type) {
			attributes.type = attributes.payment_method_type
			delete attributes.payment_method_type
		}

		const mappedFeatureToggleName = FeatureToggleNamesByProvider[FEATURE_TOGGLE_PROVIDER_SPLIT][featureToggleName] ?? featureToggleName

		return splitContext.client?.getTreatment(mappedFeatureToggleName, attributes)
	}

	const isFeatureToggleOn = (key, featureToggleName, attributes) => {
		return getFeatureToggle(key, featureToggleName, attributes) === "on"
	}

	const getFeatureToggleList = () => {
		return splitContext.factory
			.manager()
			.splits()
			.filter((split) => split.name.startsWith("MA_"))
			.sort((a, b) => a.name.localeCompare(b.name))
			.map((split) => split.name)
	}

	if (!splitContext.isReady) {
		return <Loading />
	}

	return (
		<FeatureTogglesContext.Provider
			value={{
				provider: FEATURE_TOGGLE_PROVIDER_SPLIT,
				isReady: Boolean(splitContext?.isReady),
				getFeatureToggle,
				isFeatureToggleOn,
				getFeatureToggleList,
			}}
		>
			{children}
		</FeatureTogglesContext.Provider>
	)
}

const OptimizelyFeatureTogglesProvider = ({ config, children }) => {
	const [optimizelyInstance, setOptimizelyInstance] = useState()
	const [isReady, setReady] = useState(false)

	useEffect(() => {
		const optimizelyInstance = optimizelyReactSDK.createInstance(config)
		optimizelyReactSDK.setLogLevel("warn")
		setOptimizelyInstance(optimizelyInstance)
		optimizelyInstance.onReady().then(() => {
			setReady(true)
		})
	}, [])

	const getFeatureToggle = (key, featureToggleName, attributes) => {
		if (!isReady || !optimizelyInstance) {
			return FEATURE_TOGGLE_DEFAULT_VALUE
		}

		const mappedFeatureToggleName =
			FeatureToggleNamesByProvider[FEATURE_TOGGLE_PROVIDER_OPTIMIZELY][featureToggleName] ?? featureToggleName

		return optimizelyInstance.decide(mappedFeatureToggleName, undefined, key, attributes).variationKey
	}

	const isFeatureToggleOn = (key, featureToggleName, attributes) => {
		return getFeatureToggle(key, featureToggleName, attributes) === "on"
	}

	const getFeatureToggleList = () => {
		return Object.keys(optimizelyInstance.getOptimizelyConfig().featuresMap).sort()
	}

	if (!isReady) {
		return <Loading />
	}

	return (
		<optimizelyReactSDK.OptimizelyProvider optimizely={optimizelyInstance}>
			<FeatureTogglesContext.Provider
				value={{
					provider: FEATURE_TOGGLE_PROVIDER_OPTIMIZELY,
					isReady,
					getFeatureToggle,
					isFeatureToggleOn,
					getFeatureToggleList,
				}}
			>
				{children}
			</FeatureTogglesContext.Provider>
		</optimizelyReactSDK.OptimizelyProvider>
	)
}

export default FeatureTogglesProvider
